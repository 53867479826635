import { Col, Row, Button, Input, Box, LabeledCheckbox, AlertNotification } from '@commonsku/styles';
import React, { useState, useCallback, useRef, useEffect } from 'react';

import { oauth } from '../utils';

function LoginTwoFactor({ checkPinCallback, invalidPin = false, skipCode = false, setSkipCode }) {
    const [width, setWidth] = useState(window.innerWidth);
    const [tries, setTries] = useState(0);
    const [pinInvalid, setPinInvalid] = useState(invalidPin);
    const [values, setValues] = useState(['', '', '', '', '', '']);
    const pinsRef = useRef([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const mobileScreen = width <= 900;

    const onToggleRemember = useCallback((e) => {
        setSkipCode((old) => {
            return !old;
        });
    }, [setSkipCode]);

    const checkPin = useCallback(async (pin) => {
        if (values.some(value => isNaN(value))) {
            setPinInvalid(true);
            return;
        }
        setTries(old => old + 1);
        if (tries > 3) return;

        if (checkPinCallback) {
            checkPinCallback(pin);
            return;
        }
    }, [values, checkPinCallback, tries]);

    useEffect(() => {
        if (invalidPin && values.filter(value => value !== '').length === 6 && !values.some(value => isNaN(value))) {
            setValues(['', '', '', '', '', '']);
            setActiveIndex(0);
        }
    }, [invalidPin, values]);

    useEffect(() => {
        if (mobileScreen) {
            return;
        }

        // Check for a valid pin
        if (!values.some(value => value === '')) {
            checkPin(values.join(''));
        } else {
            setPinInvalid(false);
        }
    }, [values]);

    useEffect(() => {
        pinsRef.current = pinsRef.current.slice(0, 6);
    });

    useEffect(() => {
        if (mobileScreen) {
            return;
        }
        pinsRef.current[activeIndex].focus();
    }, [activeIndex]);

    useEffect(() => {
        const handleViewportChange = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleViewportChange);
        return () => {
            window.removeEventListener("resize", handleViewportChange);
        };
    });

    const onPinChange = useCallback((index, e) => {
        const value = e.target.value.slice(-1);
        const newValues = [...values];
        newValues[index] = value;
        setValues(newValues);

        if (index < 5) {
            // Move to the next pin
            setActiveIndex(index + 1);
        }
    });

    const onPinSmallChange = useCallback((e) => {
        const value = e.target.value;

        if (value.length === 6) {
            setValues(value.split(''));
        } else if (value === '') {
            setPinInvalid(false);
        }
    });

    const onPaste = useCallback((e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData('text/plain');

        if (paste.length === 6) {
            setValues(paste.split(''));
        }
    });

    const onKeyDown = useCallback((index, e) => {
        if (e.key === 'Backspace') {
            const indexToRemove = !!values[index] ? index : index - 1;
            // Delete the value in this pin
            const newValues = [...values];
            newValues[indexToRemove] = '';
            setValues(newValues);

            if (e.key === 'Backspace' && index > 0) {
                // Move to the previous pin
                setActiveIndex(index - 1);
            }
        }
    });

    const pinInputLarge = (
        <>
            <Row>
                <Col>
                    <div>
                        {values.map((value, index) => (
                            <Input key={index} disabled={tries > 3} error={pinInvalid} name={"pin" + index}
                                value={value}
                                ref={(el) => pinsRef.current[index] = el}
                                onChange={(e) => onPinChange(index, e)}
                                onPaste={onPaste}
                                onKeyDown={(e) => onKeyDown(index, e)}
                            />
                        ))}
                    </div>
                </Col>
            </Row>
        </>
    );

    const pinInputSmall = (
        <div>
            <Row>
                <Input
                    disabled={tries > 3}
                    error={pinInvalid}
                    name="token"
                    id="token"
                    type="text"
                    inputmode="generic"
                    pattern="[0-9]{6}"
                    autoComplete="one-time-code"
                    autoFocus
                    onChange={onPinSmallChange}
                />
            </Row>
            <Row>
                <Button disabled={tries > 3} onClick={_ => checkPin(values.join(''))}>
                    Verify Code
                </Button>
            </Row>
        </div>
    );

    let errorMessage = 'You entered an incorrect code';
    if (values.some(value => isNaN(value))) {
        errorMessage = 'Code has numbers only. Try again.';
    }

    const showError = invalidPin || (values.length === 6 && values.some(value => isNaN(value)));

    return (
        <Box>
            <div style={{ margin: '20px', padding: '10px' }}>
                <Row>
                    <Col>
                        <h3 style={{ textAlign: 'center', marginTop: '1em' }}>2-Step Verification</h3>
                        <p>Please enter the 6-digit verification code generated by your authentication app</p>
                        <div className="digit-pins" style={{ display: "flex", alignItems: "top", flexDirection: "row", justifyContent: "center" }}>
                            {mobileScreen ? pinInputSmall : pinInputLarge}
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <LabeledCheckbox label="Don't ask for verification when I log in from this device" checked={skipCode} onChange={onToggleRemember} />
                        </div>
                        {showError && tries <= 3 && <div style={{ marginBottom: '1em' }}><AlertNotification alertType="error">{errorMessage}</AlertNotification></div>}
                        {invalidPin && tries > 3 && <AlertNotification alertType="error">You entered an incorrect code too many times. Please<a href="/logout.php">&nbsp;reset your password</a>.</AlertNotification>}
                        {tries <= 3 && <p style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1em' }}>Reset your account by clicking 'forgot password' on the <a href="/logout.php">login</a> page.</p>}
                    </Col>
                </Row>
            </div>
        </Box>
    );
}

export default LoginTwoFactor;
