import _ from 'lodash';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import tempReducer from '../reducers/temp';
import displayReducer from '../reducers/display';

import { UPDATE_TRIAL_PROJECT_NUMBER_SUCCESS, UPDATE_TOUR_FEEDBACK_SUCCESS, UPDATE_TOUR_STARTED_SUCCESS } from '../actions';

const identityReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TRIAL_PROJECT_NUMBER_SUCCESS:
      let job_number = action.payload.value;

      return Object.assign({}, state, { latest_trial_job_number: job_number });
    case UPDATE_TOUR_STARTED_SUCCESS:
      let tour_started = action.payload.tour_started;

      return Object.assign({}, state, { tour_started: tour_started });
  }
  return state;
};

const feedbackReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TOUR_FEEDBACK_SUCCESS:
      let id = action.payload.id;
      let new_feedback = action.payload.data.tour_step_feedback;
      new_feedback.tour_step_key = action.payload.key;

      let feedbacks = Object.assign({}, state);
      _.forEach(feedbacks, function(value, key) {
        if(value.tour_step_id === id) {
          delete feedbacks[key];
        }
      });
      feedbacks[new_feedback.tour_step_feedback_id] = new_feedback;

      return Object.assign({}, feedbacks);
  }
  return state;
};

export const reducers = {
  identity: identityReducer,
  feedbacks: feedbackReducer,
  temp: tempReducer,
  display: displayReducer
};

export default function configureStore(initialState) {
  initialState.display = {};
  initialState.display.popups = [];
  const store = createStore(
    (state=initialState, action) => {
      return {
        ...state,
        identity: identityReducer(state.identity, action),
        feedbacks: feedbackReducer(state.feedbacks, action),
        temp: tempReducer(state.temp, action),
        display: displayReducer(state.display, action)
      };
    },
    initialState,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
  );

  return store;
}

