import { useState, useEffect } from 'react';
import { window } from '../global';
import { mobileScreenSize } from '../utils';

export default function useMobileScreen() {
    const [width, setWidth] = useState((window as any).innerWidth ?? 1080);
    const mobileScreen = width <= mobileScreenSize;

    useEffect(() => {
        const handleViewportChange = () => setWidth((window as any).innerWidth ?? 1080);
        window.addEventListener("resize", handleViewportChange);
        return () => {
            window.removeEventListener("resize", handleViewportChange);
        };
    });

    return mobileScreen;
}