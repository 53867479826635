import React, { useCallback, useEffect, useState } from "react";
import LoginUsernamePassword from "../components/LoginUsernamePassword";
import "react-toastify/dist/ReactToastify.css";
import { reducers } from "../store/configureTourStore";
import withReducers from "../store/withReducers";
import { Row, Col, DownloadIcon } from "@commonsku/styles";
import useMobileScreen from "../hooks/useMobileScreen";
import { getCookie, getImageSrc, oauth, setCookie } from "../utils";
import ResetPassword from "../components/ResetPassword";
import { useDispatch, useSelector } from "react-redux";
import GeneralPageLayout from "./LoginPageLayout";
import LoginTwoFactor from "../components/LoginTwoFactor";
import { setIsLoadingFalse, setIsLoadingTrue } from "../redux/isLoading";

const LoginApp = () => {
  const dispatch = useDispatch();
  const blog = useSelector((state: any) => state.temp.blog);
  const mobileScreen = useMobileScreen();
  const [marketingAdImage, setMarketingAdImage] = useState(false);
  const [marketingAdLink, setMarketingAdLink] = useState("");
  const [hasLastLogin, setHasLastLogin] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [code, setCode] = useState("");
  const [twoFactorToken, setTwoFactorToken] = useState("");
  const [error, setError] = useState("");
  const [codeInvalid, setCodeInvalid] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [skipCode, setSkipCode] = useState(false);

  const [showTwoFactor, setShowTwoFactor] = useState(false);
  const [loadingContent, setLoadingContent] = useState(true);

  const getMarketingAd = useCallback(async () => {
    try {
      const resp = await oauth("GET", "marketing-setting/get", {
        loginpage_ad_settings: true,
        audience: null,
      });
      const ad = resp.json.settings[0];
      setMarketingAdImage(getImageSrc(ad.file_data, "original"));
      setMarketingAdLink(ad.link);
    } catch (e) {
      console.log("No marketing ad");
    }
  }, []);

  const getAccessToken = useCallback(async () => {
    dispatch(setIsLoadingTrue());
    try {
      const res = await fetch("authentication.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
          two_factor_code: code,
          two_factor_token: twoFactorToken,
          remember_two_factor: skipCode,
        }),
      });
      const data = await res.json();

      // Remember:
      if (remember) {
        setCookie("csku.login", username, 365 * 24 * 3600);
      } else {
        setCookie("csku.login", null, -1);
      }

      if (data.token) {
        const expiryTime =
          Math.floor(Date.now() / 1000) + data.token.expires_in;
        setCookie("access_token", data.token.access_token, expiryTime);

        // Set two factor token if any
        if (data.two_factor_token) {
          setCookie("commonsku_2fa", data.two_factor_token, 3600 * 24 * 365);
        }
        const urlParams = new URLSearchParams(window.location.search);
        const returnParam = urlParams.get("return");
        if (returnParam) {
          window.location.href =
            "/redirect.php?return=" + encodeURIComponent(returnParam);
        } else {
          // Third Party, reload with login auth token
          if (urlParams.get("client_id")) {
            window.location.reload();
          } else {
            window.location.href = "/redirect.php";
          }
        }
      } else {
        dispatch(setIsLoadingFalse());
        if (data.error === "two-factor-required") {
          setShowTwoFactor(true);
        } else if (data.error === "two-factor-failed") {
          setShowTwoFactor(true);
          setCodeInvalid(true);
        } else {
          setError(
            data.error.includes("user credentials")
              ? "The email or password you've entered is incorrect."
              : data.error
          );
        }
      }
    } catch (e) {
      dispatch(setIsLoadingFalse());
      // show error
      setError(e);
    }
  }, [code, password, remember, skipCode, twoFactorToken, username]);

  const setCodeCallback = (pin) => {
    setCode(pin);
  };

  useEffect(() => {
    if (code && code.length === 6) {
      getAccessToken();
    }
  }, [code, getAccessToken]);

  useEffect(() => {
    const lastLogin = getCookie("csku.last_login");
    if (lastLogin) {
      setHasLastLogin(true);
      getMarketingAd();
    }

    const twoFactorTokenFromCookie = getCookie("commonsku_2fa");
    if (twoFactorTokenFromCookie) {
      setTwoFactorToken(twoFactorTokenFromCookie);
    }

    const urlParams = new URLSearchParams(window.location.search);
    const isDownloadParam = parseInt(urlParams.get("isDownload"));
    setIsDownload(isDownloadParam === 1);
    setLoadingContent(false);
  }, [getMarketingAd]);

  let rightSideContent = null;

  if (!loadingContent) {
    rightSideContent = (
      <div className="right-side-content">
        <div className="sideContent contentBox">
          <div className="sideContentHeader">Unify Your Software and Simplify Your Workflow</div>
          <div>
            With over 800 distributor customers,
            commonsku's Connected Workflow platform transforms how you streamline operations,
            deliver exceptional customer experiences, and maintain project visibility.
            <br />
            <br />
            See how at <a href="https://www.commonsku.com">commonsku.com</a>
          </div>
        </div>
      </div>
    );

    if (hasLastLogin) {
      rightSideContent = (
        <div className="right-side-content">
          <div className="sideContent contentBoxContainer">
            <div className="topBox">
              <a href={marketingAdLink} target="_blank" rel="noreferrer">
                <div
                  style={{
                    position: "relative",
                    minHeight: "300px",
                    height: "100%",
                    backgroundImage: `url(${marketingAdImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                  }}
                >
                  <img
                    src="/images/xtrnl.svg"
                    style={{ float: "right", margin: "20px", width: "25px" }}
                    alt="external link"
                  ></img>
                </div>
              </a>
            </div>
            <div className="bottomBox">
              <a
                href={blog.link}
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}
              >
                <div>From The Blog</div>
                <h5>{blog.title}</h5>
              </a>
            </div>
          </div>
        </div>
      );
    }
  }

  const downloadContent = (
    <div className="left-side-content">
      <div className="sideContent contentBox" style={{ textAlign: "center" }}>
        <div className="sideContentHeader">
          <div>
            <DownloadIcon
              style={{ width: "50px", height: "50px" }}
              color="white"
            />
          </div>
          <div>Login to Download</div>
        </div>
        <div>
          For security reasons, you must be logged in to download files from
          commonsku.
        </div>
      </div>
    </div>
  );

  let content = (
    <>
      <Row>
        {isDownload && !mobileScreen ? (
          <Col xs={12} sm={6}>
            <Row>
              <Col xs={12}>{downloadContent}</Col>
            </Row>
          </Col>
        ) : null}
        <Col xs={12} sm={6}>
          <div style={{ margin: "36px" }}>
            <LoginUsernamePassword
              setShowReset={setShowReset}
              error={error}
              getAccessToken={getAccessToken}
              username={username}
              password={password}
              setUsername={setUsername}
              setPassword={setPassword}
              remember={remember}
              setRemember={setRemember}
            />
          </div>
        </Col>
        {!isDownload || mobileScreen ? (
          <Col xs={12} sm={6}>
            <Row>
              <Col xs={12}>
                {isDownload ? downloadContent : rightSideContent}
              </Col>
            </Row>
          </Col>
        ) : null}
      </Row>
      {showReset && (
        <ResetPassword
          setShowReset={setShowReset}
          mobileScreen={mobileScreen}
        />
      )}
    </>
  );

  if (showTwoFactor) {
    content = (
      <Row>
        <Col xs={12} sm={12}>
          <LoginTwoFactor
            checkPinCallback={setCodeCallback}
            invalidPin={codeInvalid}
            skipCode={skipCode}
            setSkipCode={setSkipCode}
          />
        </Col>
      </Row>
    );
  }
  return <GeneralPageLayout>{content}</GeneralPageLayout>;
};

export default withReducers(LoginApp, reducers, true);
