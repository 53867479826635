import { Col, Row, LabeledInput, Button, Popup, XIcon, AlertNotification } from '@commonsku/styles';
import React, { useState, useCallback } from 'react';
import { oauth } from '../utils';

function ResetPassword({ setShowReset, mobileScreen }) {
    const [username, setUsername] = useState<string>('');
    const [success, setSuccess] = useState<string>('');
    const [error, setError] = useState<string>('');

    const passwordResetRequest = useCallback(async (username: string) => {
        try {
            await oauth('POST', 'password', {
                'login_name': username,
            });
            setSuccess('Instructions on how to reset your password have been emailed to you.');
            setError('');
        } catch (e) {
            // show error
            setError('Not a valid email address. Please check your entry and try again.');
            setSuccess('');
        }
    }, []);

    const content = (
        <Popup noHeader height="auto" width={mobileScreen ? '350px' : '600px'}>
            <Row>
                <Col xs={12}>
                    <div style={{ float: 'right' }}><a onClick={() => setShowReset(false)}><XIcon /></a></div>
                    <h3>Reset Password</h3>
                </Col>
                {!!success && <AlertNotification alertType="success">{success}</AlertNotification>}
                {!!error && <AlertNotification alertType="error">{error}</AlertNotification>}
                <p style={{ marginTop: '1em' }}>Enter the email you used when you signed up, and we will send you instructions on how to reset your password.</p>
                <Col xs={12} style={{ marginTop: '1em' }}>
                    <LabeledInput
                        label='Email'
                        labelOnTop
                        name='username'
                        placeholder='you@company.com'
                        value={username}
                        onChange={(e) => {
                            const target = e.target;
                            setUsername(target.value);
                        }}
                    />
                </Col>
                <Col xs={12} style={{ marginTop: '1em' }}>
                    <div style={{ float: 'right' }}>
                        <Button disabled={!username} variant="primary" onClick={() => { passwordResetRequest(username); }}>Reset Password</Button>
                    </div>
                </Col>
            </Row>
        </Popup >
    );

    return content;
}

export default ResetPassword;
