import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { reducers } from '../store/configureTourStore';
import withReducers from '../store/withReducers';

import { createGlobalStyle } from 'styled-components';
import { Button, Theme } from '@commonsku/styles';
import ErrorBoundary from '../components/ErrorBoundary';
import './LoginPageLayout.css';
import MainSection from './MainSection';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "skufont-bold";
    src: url("fonts/TT-Norms-Pro-Bold.eot");
    src: url("fonts/TT-Norms-Pro-Bold.otf") format("opentype"),
        url("fonts/TT-Norms-Pro-Bold.ttf") format("TrueType"),
        url("fonts/TT-Norms-Pro-Bold.woff") format("woff"),
        url("fonts/TT-Norms-Pro-Bold.woff2") format("woff2");
  }
  @font-face {
    font-family: "skufont-demibold";
    src: url("fonts/TT-Norms-Pro-DemiBold.eot");
    src: url("fonts/TT-Norms-Pro-DemiBold.otf") format("opentype"),
        url("fonts/TT-Norms-Pro-DemiBold.ttf") format("TrueType"),
        url("fonts/TT-Norms-Pro-DemiBold.woff") format("woff"),
        url("fonts/TT-Norms-Pro-DemiBold.woff2") format("woff2");
  }
  @font-face {
    font-family: "skufont-medium";
    src: url("fonts/TT-Norms-Pro-Medium.eot");
    src: url("fonts/TT-Norms-Pro-Medium.otf") format("opentype"),
        url("fonts/TT-Norms-Pro-Medium.ttf") format("TrueType"),
        url("fonts/TT-Norms-Pro-Medium.woff") format("woff"),
        url("fonts/TT-Norms-Pro-Medium.woff2") format("woff2");
  }
  @font-face {
    font-family: "skufont-regular";
    src: url("fonts/TT-Norms-Pro-Regular.eot");
    src: url("fonts/TT-Norms-Pro-Regular.otf") format("opentype"),
        url("fonts/TT-Norms-Pro-Regular.ttf") format("TrueType"),
        url("fonts/TT-Norms-Pro-Regular.woff") format("woff"),
        url("fonts/TT-Norms-Pro-Regular.woff2") format("woff2");
  }

  .csku-styles {
    font-family: ${props => (props.theme as any).fontFamily};
  }
`;

const GeneralPageLayout = ({ children }) => {
    return (
        <Theme>
            <GlobalStyle theme={{ fontFamily: "'skufont-regular', 'sans-serif', 'Roboto'" }} />
            <ErrorBoundary>
                <MainSection no_sidebar>
                    <div className="main-content csku-styles" style={{ marginTop: 0, padding: 0, paddingBottom: '24px' }}>
                        <div className="row full-width">
                            <div className="row full-width">
                                <div className='login-sticky-container'>
                                    <div className='login-header'>
                                        <div className='login-lft'>
                                            <a href="https://www.commonsku.com/"><img src="/images/commonsku-main-logo-blue.svg" alt="commonsku" /></a>
                                        </div>
                                        <div className='login-right'>
                                            <Button variant="secondary" onClick={() => { window.location.href = 'https://www.commonsku.com'; }}>Learn More</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className='login-content'>
                                    <div className='login-content-center'>
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        autoClose={3000}
                        hideProgressBar={true}
                    />
                </MainSection>
            </ErrorBoundary>
        </Theme >
    );
};

export default withReducers(GeneralPageLayout, reducers, true);
