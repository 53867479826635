import {
  Col,
  Row,
  LabeledIconInput,
  LabeledInput,
  Button,
  Link,
  colors,
  Box,
  AlertNotification,
  LabeledCheckbox,
  SkubotSpinner,
} from "@commonsku/styles";
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { getCookie, validateEmail } from "../utils";
import useMobileScreen from "../hooks/useMobileScreen";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../redux/isLoading";

function LoginUsernamePassword({
  setShowReset,
  getAccessToken,
  error,
  username,
  setUsername,
  password,
  setPassword,
  remember,
  setRemember,
}) {
  const mobileScreen = useMobileScreen();
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [isTouched, setIsTouched] = useState({
    email: false,
    password: false,
  });
  const isLoading = useSelector(selectIsLoading);

  const handleInputChange = (value: string) => {
    setIsTouched((prevValue) => ({
      ...prevValue,
      [value]: true,
    }));
  };

  useEffect(() => {
    const loginName = getCookie("csku.login");
    if (loginName) {
      setUsername(loginName);
      setRemember(true);
      handleInputChange("email");
      passwordRef.current.focus();
    } else {
      usernameRef.current.focus();
    }
  }, [setRemember, setUsername]);

  const onToggleRemember = useCallback(() => {
    setRemember((old) => {
      return !old;
    });
  }, [setRemember]);

  const emailIsValid = isTouched.email && validateEmail(username);

  const passwordIsValid = isTouched.password && !!password;

  const formIsValid = emailIsValid && passwordIsValid;

  const buttonProps = useMemo(() => {
    return isLoading ? <SkubotSpinner size="button" skubot={false} /> : "Login";
  }, [isLoading]);

  const loginContent = (
    <Row>
      <Col xs={12}>
        <h3>Login to commonsku</h3>
      </Col>
      <Col xs={12}>
        <LabeledInput
          ref={usernameRef}
          label="Work Email"
          labelOnTop
          name="username"
          placeholder="you@company.com"
          autoComplete="username"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
            handleInputChange("email");
          }}
          error={isTouched.email && !emailIsValid}
        />
      </Col>
      <Col xs={12}>
        <LabeledIconInput
          ref={passwordRef}
          labelOnTop
          label="Password"
          name="password"
          placeholder="Your password"
          autoComplete="currrent-password"
          password
          Icon={<Link onClick={() => setShowReset(true)}>forgot?</Link>}
          iconPosition="right"
          iconLabelStyles={{
            width: "auto",
            fontSize: 11,
            background: "transparent",
            color: colors.teal.main,
          }}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            handleInputChange("password");
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              getAccessToken();
            }
          }}
          error={isTouched.password && !passwordIsValid}
        />
      </Col>
      {!!error && (
        <Col xs={12} style={{ marginTop: "1em" }}>
          <AlertNotification alertType="error">
            <div>
              <p>{error}</p>
              <p>
                <a href="#" onClick={() => setShowReset(true)}>
                  Forgot Password?
                </a>
              </p>
            </div>
          </AlertNotification>
        </Col>
      )}
      <Col xs={12} style={{ marginTop: "2em" }}>
        <Row>
          <Col xs={6}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                height: "50px",
              }}
            >
              <div>
                <LabeledCheckbox
                  label="Remember me"
                  checked={remember}
                  onChange={onToggleRemember}
                />
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <div style={{ float: "right" }}>
              <Button
                disabled={!formIsValid || isLoading}
                variant="cta"
                onClick={() => {
                  getAccessToken();
                }}
                style={{
                  width: 95,
                  display: "flex",
                  justifyContent: "center",
                  cursor: !formIsValid || isLoading ? "not-allowed" : "pointer",
                }}
              >
                {buttonProps}
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  let loginFlow = loginContent;
  if (!mobileScreen) {
    loginFlow = (
      <Box>
        <div
          style={{
            background: "white",
            height: "48%",
            minHeight: "535px",
            padding: "36px",
          }}
        >
          {loginContent}
        </div>
      </Box>
    );
  }

  return (
    <Row>
      <Col xs={12}>{loginFlow}</Col>
    </Row>
  );
}

export default LoginUsernamePassword;
